//@import url("../../../node_modules/@mdi/font/css/materialdesignicons.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Arial";
  font-display: swap;
}

@layer base {
  h1 {
    @apply text-4xl;
    color: #4D5158;
    line-height: 1.3;
    @media (max-width: 768px) {
      .primary-service {
        font-size: 2.2rem;
      }
    }
  }

  h2 {
    @apply text-xl;
  }

  input {
    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
    }
  }

  button {
    -webkit-tap-highlight-color: unset !Important;

    &:hover {
      background: none;
    }
  }
}

$foreground-rgb: 0, 0, 0;
$background-start-rgb: 214, 219, 220;
$background-end-rgb: 255, 255, 255;
$primary-glow: conic-gradient(
                from 180deg at 50% 50%,
                #16abff33 0deg,
                #0885ff33 55deg,
                #54d6ff33 120deg,
                #0071ff33 160deg,
                transparent 360deg
);
$secondary-glow: radial-gradient(
                rgba(255, 255, 255, 1),
                rgba(255, 255, 255, 0)
);
$tile-start-rgb: 239, 245, 249;
$tile-end-rgb: 228, 232, 233;
$tile-border: conic-gradient(
                #00000080,
                #00000040,
                #00000030,
                #00000020,
                #00000010,
                #00000010,
                #00000080
);
$callout-rgb: 238, 240, 241;
$callout-border-rgb: 172, 175, 176;
$card-rgb: 180, 185, 188;
$card-border-rgb: 131, 134, 135;
$textColor: #4D5158;
$primaryColor: #264EC7;
$secondaryBackground: #f3f3f3;

.icons-edit:before {
  font-size: 19px !important;
  color: #BFBFBF !important;
  text-decoration-color: #BFBFBF !important;
}

.flag-button {
  padding: 0 !important;
  display: flex;
  place-content: center;

  button {
    min-height: 52px;
    min-width: 84px;

    &:after {
      content: unset !important;
    }

    @media (max-width: 768px) {
      min-width: 45px;
      justify-content: center;
      padding: 0 18px 0 10px;

      span {
        flex-direction: column;
        padding-left: 10px;
      }
    }
  }

  ul {
    width: 100%;
    min-width: 314px;
  }
}

.input-pattern {
  min-height: 48px;
  font-size: 16px;
  text-align: start;
  @media (max-width: 768px) {
    font-size: 14px
  }
  @media (max-width: 355px) {
    font-size: 12px
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0
}

html, body {
  scroll-behavior: smooth;
}

body {
  color: $textColor;
  scroll-behavior: smooth;
  margin: 0
}

html {
  scrollbar-gutter: stable;
}

body[style] {
  margin: 0 auto !important;
}

a {
  color: inherit;
  text-decoration: none;
}

.style-like-h1 {
  @apply text-4xl;
  color: #4D5158;
  line-height: 1.3;
  @media (max-width: 768px) {
    @apply text-2xl;
  }
}

.cadastrese-setup {
  width: 100%;
  min-height: 350px;
  background-image: url("../../../public/home/cadastro.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right 50% bottom 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 10px;
  flex-direction: column;
}

.image-home {
  width: 328px;
  height: 335px;
}

.text-primary-important {
  color: $textColor !important;
}

.responsive-input-cadastrese .select__value-container {
  min-height: 56px;
}

.select__input-container {
  z-index: 999;
}

.responsive-input-cadastrese {
  width: 17.5rem;
  height: 58px;
}

.responsive-input-button {
  width: 35.6rem;
}

.responsive-buscar-text {
  font-size: 16px;
}

@media (max-width: 1280px) {
  .responsive-input-cadastrese {
    width: 30rem;
  }
  .responsive-input-button {
    width: 30rem;
  }
}

@media (max-width: 1024px) {
  .border-top-mobile {
    border-top: 4px solid $secondaryBackground;
  }

  .border-bottom-mobile {
    border-bottom: 4px solid $secondaryBackground;
  }
}

@media (max-width: 767px) {
  .cadastrese-setup {
    min-height: 550px;
    padding: 15px;
  }

  .responsive-buscar-text {
    font-size: 14px;
  }
}

@media (max-width: 522px) {
  .responsive-input-cadastrese {
    width: 20rem;
  }

  .responsive-input-button {
    width: 20rem;
  }

  .responsive-buscar-text {
    font-size: 12px;
  }
}

@media (max-width: 386px) {
  .responsive-input-cadastrese {
    width: 18rem;
  }

  .responsive-input-button {
    width: 18rem;
  }

  .responsive-buscar-text {
    font-size: 12px;
  }
}

@media (max-width: 352px) {
  .responsive-input-cadastrese {
    width: 13rem;
  }

  .responsive-input-button {
    width: 13rem;
  }
}

.styled-search-component {
  box-shadow: 2.1463427543640137px 2.1463427543640137px 6.439028263092041px 0px #00000033;
}

.secondary-background {
  background-color: $secondaryBackground;
}

.text-bg-primary {
  transition: all 0.3s;
  background-color: $primaryColor;
  color: white;

  &:hover {
    background: none;
    border: 1px solid $primaryColor;
    color: $primaryColor;
  }
}

.reverse-text-bg-primary {
  width: 141px;
  height: 36px;
  border: 2px solid $primaryColor;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;

  span {
    color: $primaryColor;
  }

  &:hover {
    background: $primaryColor;

    span {
      color: #FFFFFF !important;
    }
  }
}

.extended-bg {
  position: relative;
  height: fit-content;
  width: 100%;

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: 0;
    width: 100%;
    height: 210px;
    background-color: #f3f3f3;
  }
}

.text-bg-secondary {
  transition: all 0.3s;
  border: 1px solid #F9AD29;
  background-color: #F9AD29;
  color: #2E2E2E;

  &:hover {
    background: #e79b18;
  }
}

.block-title {
  width: 100%;
  font-size: 24px;
  font-weight: 600
}

.line-height-sm {
  line-height: 1.2
}

.fs-12 {
  font-size: 12px;
}

.modal-orcamento {
  width: 92%;
  z-index: 999;
  padding: 20px;
  background-color: #264EC7;
  color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgb(0, 0, 0);
  flex-direction: column;
  text-align: center;
  display: flex;
  justify-content: center;
  height: 60%;
  align-self: flex-end;
  margin-bottom: 1rem;

  @media (min-width: 588px) {
    height: 64%;
    width: 95%;
  }

  @media(min-width: 768px) {
    height: 80%;
    width: 100%;
    margin-bottom: 0;
  }
}

.modal-lead {
  text-align: center;
  background-color: $primaryColor;
  color: white;
  width: 92%;
  height: 60%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 999;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgb(0, 0, 0);
  padding: 20px;
  position: fixed;

  @media (min-width: 588px) {
    height: 50%;
    width: 94%;
  }

  @media(min-width: 768px) {
    height: 40%;
    width: 62%;
    margin-bottom: 0;
  }
}

.modal-close-button {
  width: 141px;
  height: 36px;
  border: 1px solid white;
  color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
}

.card {
  padding: 20px;
  background-color: #fff;
  border-radius: 17px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.bg-frame {
  background-color: $primaryColor;
  border-radius: 17px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  width: 621px;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 371px) {
    width: 100%;
  }
}

.w-ajust {
  width: 49%;
  @media (max-width: 768px) {
    width: 100%;
  }
}

w-90 {
  width: 90%;
}

@media (prefers-color-scheme: dark) {
  html {
    //color-scheme: dark;
  }
}

.rightContentSegmentos {
  position: absolute;
  right: -20px;
  top: 0;
  z-index: 10;
}

.leftContentSegmentos {
  z-index: 11;
}

.primary-service {

  font-size: 2.2rem;
}

@import url("../../../node_modules/@mdi/font/css/materialdesignicons.css");
